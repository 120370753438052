import React from 'react';
import styled from 'styled-components';

const Div = styled.div`

  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }  

`

const VideoEmbed = ({url}) => {
  return (
    <Div>
      <iframe src={url} frameBorder="0" allowFullScreen></iframe>
    </Div>
  )
}

export default VideoEmbed
