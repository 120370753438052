import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import theme from '../theme'
import Img from 'gatsby-image'
import Button from './Button'
import petrol from '../images/pertrol.svg'
import gearbox from '../images/gearbox.svg'
import cogwheel from '../images/cogwheel.svg'
import wrench from '../images/wrench.svg'
import pickupCar from '../images/pickup-car.svg'
import suspension from '../images/suspension.svg'

const OurServices = () => {


  const { engine, car, shapes } = useStaticQuery(graphql`
  {
    car: file(name: {eq: "mechanic-62"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    engine: file(name: {eq: "mechanic-94"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapes: file(name: {eq: "mechanic-60"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)


  const [{ offset }, set] = useSpring(() => ({ offset: 0 }))

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top;
    const offset = window.pageYOffset - posY;
    set({ offset });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const ref = useRef(null)

  const calc = offset => `translateY(${offset * 0.1}px)`

  return (
    <div style={{ minHeight: "calc(100vh - 88px)", width: "100%", position: "relative"}}>

      {/* Wrapper  */}
      <StyledWrapper>

        {/* Right Parallax section */}
        <StyledParallaxSection>
          <StyledParallaxWrapper>
            <animated.div ref={ref} style={{ transform: offset.interpolate(calc) }} className='parallaxWrapper-inner'>
              <StyledBackground tag='div' fluid={engine.childImageSharp.fluid}/>
            </animated.div>
            <StyledShapes tag='section' fluid={shapes.childImageSharp.fluid} />
          </StyledParallaxWrapper>
        </StyledParallaxSection>

        {/* Right section */}
        <StyledSection>
	  <h1>
	    Our Services
	  </h1>
	  <div >
	    <ul className="service-list">
	      <li>
		<img src={petrol} width="40x" alt="" />
		<span>Vehicle Services</span>
	      </li>
	      <li>
		<img src={pickupCar} width="40x" alt="" />
		<span>Warrant of Fitness</span>
	      </li>
	      <li>
		<img src={suspension} width="40x" alt="" />
		<span>Suspension Repairs</span>
	      </li>
	      <li>
		<img src={wrench} width="40x" alt="" />
		<span>Vehicle Repairs</span>
	      </li>
	      <li>
		<img src={cogwheel} width="40x" alt="" />
		<span>Breakdown Repairs</span>
	      </li>
	      <li>
		<img src={gearbox} width="40x" />
		<span>Gearbox Repairs</span>
	      </li>
	    </ul>
	  </div>
	  <div style={{zIndex: '100'}} className="content-center"><Button type="noneToGreen" to="/contact">Contact Us</Button></div> 
        </StyledSection>
      </StyledWrapper>
      <StyledImageWrapper>
	<Img style={{width: '100%'}} fluid={car.childImageSharp.fluid}/>
      </StyledImageWrapper>
    </div>
  )
}

export const StyledParallaxWrapper = styled.div`
  overflow:hidden;
  height: 100%;
  width: 100%;

  .parallaxWrapper-inner {
    height: 100%;
    width: 100%;
  }
`

const StyledSection = styled.section`
  position: relative;  
  padding-top: 150px;

  h1 {
    font-family: 'Khand';
    font-size: 60px; 
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    margin-bottom: 0.30em;
  }
  
  p {
    font-size: 24px;
    max-width: 600px;
    line-height: 2em;
    font-weight: 600;
    color: #666;
  }

  .service-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    
    img {
      margin-right: 20px;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
      padding: 50px 0;
    }
    
  }

  @media(min-width: 984px) {
    h1 {
      font-size: 80px; 
    }
  }
  
  @media(max-width: 400px) {
    .service-list {
      li {
	font-size: 14px;
      }
      img {
	margin-right: 10px;
      }
    }
  }

  @media(max-width: 1024px) {
    margin: auto;
    padding-left: 0;
  }
  
`

const StyledWrapper = styled.div`
  display: grid;
  margin: 0; 
  grid-template-columns: 40vw 1fr;
  height: 100%;
  gap: 40px;
  padding-bottom: 30vw;
  padding-right: 200px;
  
  @media(min-width: 650px) {
    padding-bottom: 300px;
  }

  @media(max-width: 1024px) {
    max-width: 85%;
    margin: auto;
    display: block;
    // grid-template-columns: 100%;
    padding-right: 0;
  }
`

const StyledParallaxSection = styled.div`
  width: 40vw;
  position: relative;
  
  @media(max-width: 1024px) {
    display: none;
  }
`
  
const StyledImageWrapper = styled.div`
  padding-top: 50px;
  width: 100%;
  max-width: 1200px;
  position: absolute;
  bottom: 0;
  transform: translateY(50%) 
`

const StyledBackground = styled(BackgroundImage)`  
  height: 100%;
  width: 40vw;
  background-position: center top;
`
const StyledShapes = styled(BackgroundImage)`  
  height: 100%;
  width: 40vw;
  background-position: right top;
  position: absolute !important;
  top: 0;
  left: 0;
`

export default OurServices
