import React from "react"
import HomePageHero from "../components/HomePageHero"
import OurServices from "../components/OurServices"
import Layout from "../components/layout"
import WhyChooseUs from "../components/WhyChooseUs"
import AboutUs from "../components/AboutUs"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo />
    <HomePageHero />
    <OurServices />
    <WhyChooseUs />
    <AboutUs />
  </Layout>
)

export default IndexPage
