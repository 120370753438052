import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import theme from "../theme"
import Button from "./Button"

const HomePageHero = () => {
  const { page, car, shapes } = useStaticQuery(graphql`
    {
      page: contentfulHomePage {
        heroDescription
      }

      car: file(name: { eq: "mechanic-90" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shapes: file(name: { eq: "mechanic-59" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [{ offset }, set] = useSpring(() => ({ offset: 0 }))

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top
    const offset = window.pageYOffset - posY
    set({ offset })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const ref = useRef(null)

  const calc = offset => `translateY(${offset * 0.1}px)`

  return (
    <div
      css={`
        height: calc(100vh - 88px);
        width: 100%;
        position: relative;
        overflow: hidden;

        @media (max-width: 1024px) {
          height: auto;
        }
      `}
    >
      {/* Wrapper  */}
      <StyledWrapper className="container">
        {/* Left section */}
        <StyledSection>
          <div className="black-stripe" />
          <div className="primary-underline">
            <h1>
              Repair.
              <br />
              Modify.
              <br />
              Upgrade.
            </h1>
          </div>
          <p>{page.heroDescription}</p>
          <div className="content-center">
            <Button type="greenToOutline" to="book-appointment">
              book appointment
            </Button>
          </div>
        </StyledSection>

        {/* Right Parallax section */}
        <StyledParallaxSection>
          <StyledParallaxWrapper>
            <animated.div
              ref={ref}
              style={{ transform: offset.interpolate(calc) }}
              className="parallaxWrapper-inner"
            >
              <StyledBackground tag="div" fluid={car.childImageSharp.fluid} />
            </animated.div>
            <StyledShapes tag="section" fluid={shapes.childImageSharp.fluid} />
          </StyledParallaxWrapper>
        </StyledParallaxSection>
      </StyledWrapper>
    </div>
  )
}

export const StyledParallaxWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;

  .parallaxWrapper-inner {
    height: 100%;
    width: 100%;
  }
`

const StyledSection = styled.section`
  position: relative;

  .black-stripe {
    height: 80px;
    width: 100vw;
    transform: skew(-22deg);
    position: absolute;
    top: 0;
    right: 20%;
    background: black;
  }

  h1 {
    font-family: "Khand";
    font-size: 100px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
    color: #333;
    margin-top: 1em;
    margin-bottom: 0.3em;
  }

  p {
    font-size: 24px;
    max-width: 600px;
    line-height: 2em;
    font-weight: 600;
    color: #666;
  }

  @media (min-width: 984px) {
    h1 {
      font-size: 8vw;
    }
  }
`

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 85% !important;
    grid-template-columns: 100%;
  }
`

const StyledParallaxSection = styled.div`
  width: 50vw;
  position: relative;

  @media (max-width: 1024px) {
    display: none;
  }
`

const StyledBackground = styled(BackgroundImage)`
  height: 100%;
  width: 50vw;
  background-position: left top;
`
const StyledShapes = styled(BackgroundImage)`
  height: 100%;
  width: 50vw;
  background-position: top left;
  position: absolute !important;
  top: 0;
  left: 0;
`

export default HomePageHero
