import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import VideoEmbed from './VideoEmbed'
import Button from './Button'

const AboutUs = () => {

  const { page } = useStaticQuery(graphql`
  {
    page: contentfulHomePage {
      aboutUsDescription{
  aboutUsDescription
      }
      videoUrl
    }
  }
  `)

  return (
    <StyledSection>
      <div className="wrapper">
        <div id="au-description">
          <h1 className="primary-underline">About Us</h1>
          <p>{page.aboutUsDescription.aboutUsDescription}</p>
          <div style={{zIndex: '100'}} className="content-center"><Button type="noneToGreen" to="/contact">Contact Us</Button></div>
        </div>

        <div className="video">
          <VideoEmbed url={page.videoUrl}/>
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.div`

  background: linear-gradient(-67deg,#eaeaf1 47%,#ffffff 0%);
  padding-top: 100px;
  position: relative;

  .wrapper {
    margin: auto;
    max-width: 1080px;
  }

  #au-description {
    max-width: 520px;
  }

  h1 {
    font-family: 'Khand';
    font-size: 60px;
    color: #333;
    text-transform: uppercase;
  }

  p {
    white-space: pre-line;
    color: #666;
    font-size: 18px;
    font-weight: 600;
    line-height: 2em;
  }

  @media(max-width: 1024px) {
    .wrapper {
      max-width: 85%;
    }
  }

  @media(min-width: 800px) {
    .wrapper {
      padding-bottom: 700px;
    }
    .video {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 80%;
      max-width: 1072px;
      padding-top: 80px;
      overflow-x: hidden;
      &::before {
        content: '';
        height: 80px;
        width: 100vw;
        transform: skew(-22deg);
        position: absolute;
        top: 0;
        left: 70%;
        background: #00081e;
      }
    }
  }

`

export default AboutUs
