import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import policy from '../images/policy.svg'
import pickupCar from '../images/pickup-car.svg'
import courtesyCar from '../images/couresycar.svg'
import Button from './Button'
import free from '../images/free-tag.svg'
import BackgroundImage from 'gatsby-background-image'

const WhyChooseUs = () => {
  
  useEffect(() => {

    function scrollTo() {
      var links = document.getElementsByTagName('a');
      for (var i = 0; i < links.length; i++) {
        var link = links[i];
        if ((link.href && link.href.indexOf('#') != -1) && ((link.pathname == window.location.pathname) || ('/' + link.pathname == window.location.pathname)) && (link.search == window.location.search)) {
          link.onclick = scrollAnchors;
        }
      }
    }

    scrollTo();

    function scrollAnchors(e) {
      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
      e.preventDefault();
      const targetAnchor = document.querySelector("#au-description");
      if (!targetAnchor) return;
      const originalTop = distanceToTop(targetAnchor) - 120;
      window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
    }
  });

  const { page, image } = useStaticQuery(graphql`
  {
    image: file(name: {eq: "mechanic-05"}) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    page: contentfulHomePage {
	  whyChooseUsDescription {
	whyChooseUsDescription
      }
    }
  }

  `)
  
  return (
    <StyledSection>
      <div className="wrapper">

	<div>
	  <h1 className="primary-underline">Why Choose automotive new lynn</h1>
	  <p>
	    {page.whyChooseUsDescription.whyChooseUsDescription}
  	  </p>
	  <ul className="service-list">
	    <li><img src={policy} alt="" /><span>ltnz certified</span></li>
	    <li><img src={courtesyCar} alt="" /><span>courtesy cars</span></li>
	    <li><img src={pickupCar} alt="" /><span>wof authorized</span></li>
	    <li><img src={free} alt="" /><span>free quotes</span></li>
	  </ul>
	  <Button to="#au-description" type='whiteNoneToGreen'>More about us</Button>
	</div>

	<aside >
	  <BackgroundImage className="list-bg" style={{width: '100%'}} fluid={[ "linear-gradient(180deg,#40a629 0%,rgba(64,166,41,0) 100%)", image.childImageSharp.fluid]}>
	    <ul>
	      <li><span>Mechanic</span></li>
	      <li><span>Car Accessories</span></li>
	      <li><span>Breakdown Services</span></li>
	      <li><span>Diagnosis</span></li>
	      <li><span>Checkup</span></li>
	    </ul>
	  </BackgroundImage>
	</aside>

      </div>
       
    </StyledSection>
  )
}


const StyledSection = styled.section`
  padding-top: 350px;
  padding-bottom: 50px;
  background: #021827;
 
  aside {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 160px;
    .list-bg {
      display: flex;
      padding-left: 80px;
      box-sizing: border-box;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      min-height: 520px;
    }

    ul {
      box-sizing: border-box;
      li {
	padding-bottom: 23px;
      }
    }

  }

  h1 {
    text-transform: uppercase;
    color: white;
    font-size: 60px;
    font-family: 'Khand';
    padding-bottom: 20px;
  }
  
  p {
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 2em;
  }

  .service-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    
    img {
      width: 40px;
      margin-right: 20px;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-transform: uppercase;
      color: white;
      font-weight: bold;
      padding: 50px 0;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 20px));
    margin: auto;
    max-width: 1080px;
    gap: 40px;
  }
  @media(max-width: 800px) {
    aside {
      display: none;
    }
    .wrapper {
      display: block;
    }
  }
  @media(max-width: 1024px) {
    .wrapper {
      max-width: 85%;
    }
  }
`

export default WhyChooseUs
